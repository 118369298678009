<template>
  <v-container fluid class="pt-6 mt-6">
    <Project />
  </v-container>
</template>

<script>
export default {
  name: "HomePage",
  components: {
    Project: () => import("~cp/Project/Project"),
  },
};
</script>
